import Process from '../Process'
import Contact from './Contact'
import Hero from './Hero'
import Industries from './Industries'
import Services from './Services'
import Testimonials from './Testimonials'

export default function HomePage() {
    return (
        <div className="home">
            <Hero />
            <Services />
            <Industries />
            <Testimonials />
            <Process />
            <Contact />
        </div>
    )
}

import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

export default function Contact() {
	const [loader, setLoader] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseType, setResponseType] = useState(null);
	const [alert, setAlert] = useState(false);

	const [data, setData] = useState({
		name: "",
		email: "",
		phone_no: "",
		subject: "",
		message: "",
		type: "contactus",
	});

	let config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const submitContactForm = (e) => {
		e.preventDefault();
		setLoader(true);
		setAlert(false);
		axios
			.post(`https://codexnow.com/contact/contactus`, data, config)
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setAlert(true);
					setLoader(false);
					setResponse("Your message has been sent. Thank you!");
					setResponseType("success");
				}
			})
			.catch(function (error) {
				console.log(error);
				setLoader(false);
				setAlert(true);
				setResponse("Please try again!");
				setResponseType("danger");
			});
	};

	return (
		<section className="contact" id="contact">
			<div className="codex-container">
				<div className="section-title" data-aos="fade-up">
					<h2>Contact Us</h2>
					<p>
						Drop us a line about your project requirements at info@codexnow.com or via the contact form below, and we will contact you within a business day.
					</p>
				</div>
				<Row className="g-4 justify-content-center mb-4" data-aos="fade-up" data-aos-delay="100">
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-location-dot"></i>
							<h3>US Office</h3>
							<p>200 Spectrum, Center Drive</p>
							<p>Suite 300, Irvine, CA 92618</p>
						</div>
					</Col>
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-location-dot"></i>
							<h3>Malaysia Office</h3>
							<p>Office 22-02, Plaza 137, Jalan Ampang</p>
							<p>Kuala Lumpur, Malaysia</p>
						</div>
					</Col>
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-location-dot"></i>
							<h3>Indonesia Office</h3>
							<p>Palma One, 2nd Floor Suite 210</p>
							<p>Jl. HR Rasuna Said Kav. X-2 No. 4</p>
							<p>Jakarta 12950, Indonesia</p>
						</div>
					</Col>
				</Row>
				<Row className="g-4 justify-content-center mb-5" data-aos="fade-up" data-aos-delay="200">
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-location-dot"></i>
							<h3>Singapore Office</h3>
							<p>10 Kallang Avenue #12-10</p>
							<p>Tower 2, Singapore 339511</p>
						</div>
					</Col>
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-envelope"></i>
							<h3>Email Address</h3>
							<p>
								<a href="mailto:info@codexnow.com">info@codexnow.com</a>
							</p>
						</div>
					</Col>
					<Col xxl={3} lg={4}>
						<div className="contact-box">
							<i className="fa-regular fa-phone"></i>
							<h3>Phone Number</h3>
							<p>
								<a href="tel:9493928449">(949) 392-8449</a>
							</p>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center" data-aos="fade-up" data-aos-delay="300">
					<Col xxl={5} lg={6}>
						<Form onSubmit={(e) => submitContactForm(e)} className="contact-form">
							<Form.Group className="mb-3 text-start">
								<Form.Control
									type="text"
									placeholder="Full Name*"
									required
									name="name"
									onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									}
								/>
							</Form.Group>
							<Form.Group className="mb-3 row text-start">
								<Col sm="6">
									<Form.Control
										type="email"
										placeholder="Email*"
										required
										name="email"
										onChange={(e) =>
											setData({
												...data,
												[e.target.name]: e.target.value,
											})
										}
									/>
								</Col>
								<Col sm="6" className="mt-3 mt-sm-0">
									<Form.Control
										type="tel"
										placeholder="Phone No*"
										required
										name="phone_no"
										onChange={(e) =>
											setData({
												...data,
												[e.target.name]: e.target.value,
											})
										}
									/>
								</Col>
							</Form.Group>
							<Form.Group className="mb-3 text-start">
								<Form.Control
									type="text"
									placeholder="Subject*"
									required
									name="subject"
									onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									}
								/>
							</Form.Group>
							<Form.Group className="mb-3 text-start">
								<Form.Control
									as="textarea"
									rows={6}
									placeholder="Message*"
									required
									name="message"
									onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									}
								/>
							</Form.Group>
							{alert ? (
								<Alert variant={responseType} onClose={() => setAlert(false)} dismissible>
									{response}
								</Alert>
							) : null}
							<Button type="submit" className="main-btn" disabled={loader ? true : false}>
								{loader ? <Spinner animation="border" size="sm" /> : "Send Message"}
							</Button>
						</Form>
					</Col>
					<Col xxl={5} lg={6}>
						<div className="w-100 mt-4 mt-lg-0">
							<iframe
								title="contact"
								width="100%"
								height="408"
								frameBorder="0"
								scrolling="no"
								marginHeight="0"
								marginWidth="0"
								src="https://maps.google.com/maps?width=100%25&amp;height=366&amp;hl=en&amp;q=200%20SPECTRUM,%20CENTER%20DRIVE,%20SUITE%20300,%20IRVINE,%20CA%2092618+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
							></iframe>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
}

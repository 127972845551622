import React from "react";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Process from "../Process";
import Contact from "../Contact";

import MachineLearning from "../../img/services/data-science/machine-learning.jpg";
import DeepLearning from "../../img/services/data-science/deep-learning.jpg";
import BusinessIntelligence from "../../img/services/data-science/business-intelligence.jpg";
import TimeseriesForecasting from "../../img/services/data-science/timeseries-forecasting.jpg";
import Iot from "../../img/services/data-science/iot.jpg";
import MLOps from "../../img/services/data-science/mlops.jpg";

export default function DataScience() {
	return (
		<>
			<div className="main-content">
				<div className="content-header">
					{/* <img src={Banner} alt="banner" /> */}
					<div className="codex-container">
						<div className="header-text-content">
							<h2>Data Science and AI</h2>
							<p>
								At CodexNow , you will find professional teams of data scientists , subject matter experts , machine
								learning engineers , data engineers , usability and quality engineers working together to build
								customized AI based solutions . We help enterprises in every stage of the data science life cycle
								including requirements engineering , data collection , data cleaning and pres-processing ,
								exploratory data analysis , model training , deployment and maintenance. We provide a complete range
								of services starting from POC to full scale deployment.
							</p>
						</div>
					</div>
				</div>
				<div className="cta-banner engineering">
					<h2>Enhance your business with state-of-the-art Machine Learning and AI Solutions</h2>
					<HashLink to="/services/data-science/#contact" className="btn">
						Contact Us
					</HashLink>
				</div>
				<div className="content">
					<div className="codex-container">
						<Row className="gx-5 mt-5 mt-sm-0">
							<Col xl={4} md={6} className="mb-5" id="machine-learning">
								<div className="content-box">
									<div className="image-area">
										<img src={MachineLearning} alt="data-science" />
									</div>
									<div className="text">
										<h3>Machine Learning</h3>
										<p>
											Codexnow now has an experienced team of data engineers , ML engineers, AI experts and data
											scientists who work in collaboration on machine learning use cases. We provide consultancy for
											machine learning use case analysis and full-cycle development services for building the data
											pipelines, training and testing of different machine learning models followed by POC , full implementation
											and monitoring. We provide 360 degree services and support for building smart and AI based
											applications in banking , healthcare , education , entertainment , insurance and retail.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="deep-learning">
								<div className="content-box">
									<div className="image-area">
										<img src={DeepLearning} alt="data-science" />
									</div>
									<div className="text">
										<h3>Deep Learning</h3>
										<p>
											We leverage deep learning models to solve complex problems involving images , audio , video and
											sequential data streams. Deep Learning solutions have unique storage ,processing and infrastructure
											requirements . Our deep learning engineers and experts devise , train and deploy optimal deep
											learning models in various industries including healthcare, medicine , security , retail ,
											manufacturing , industrial automation etc to solve various business problems related to object
											detection , threat detection , language translation , autonomous systems , robotics , self-driving
											cars , virtual assistants and more.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="business-intelligence">
								<div className="content-box">
									<div className="image-area">
										<img src={BusinessIntelligence} alt="data-science" />
									</div>
									<div className="text">
										<h3>Business Intelligence</h3>
										<p>
											You can start your analytical journey and data driven decision making by creating a data warehouse ,
											building a business intelligence solution and deriving insights from your historical data .
											Considering the business problem , our BI & DWH experts identify the data sources , build ETL
											pipelines , perform dimensional modeling and design the visualizations and dashboard. We offer
											customized services to build , deploy and maintain BI & DWH solutions tailoring to the needs of
											different industries and business processes including sales, marketing, manufacturing , inventory
											management , operations and supply chain.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="timeseries-forecasting">
								<div className="content-box">
									<div className="image-area">
										<img src={TimeseriesForecasting} alt="data-science" />
									</div>
									<div className="text">
										<h3>Timeseries Forecasting</h3>
										<p>
											Timeseries forecasting allows to make future predictions regarding different business , weather and
											socio-economic indicators. Codexnow with the help of various statistical and deep learning models,
											builds real time forecasting systems that provide valuable insights into the future. Different use
											cases include sales and inventory forecasting , weather predictions , stock market predictions ,
											forecasting financial indicators like gdp growth and inflation etc. Timeseries analysis helps in
											understanding seasonal variations , random fluctuation, business cycles and trends which can help in
											proper financial and urban planning and risk management.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={Iot} alt="data-science" />
									</div>
									<div className="text">
										<h3>Internet of Things (IoT)</h3>
										<p>
											Codexnow offers its diverse range of services ranging from installation and embedding of sensors to
											setup an IoT infrastructure for the intended use case , design and develop an application enabling
											managing those IoT devices , data management and storage , IoT analytics and complete support to
											manage the infrastructure. Various use cases include development of wearable devices for healthcare
											, IoT for agriculture , retail , manufacturing , logistics , smart cities , smart vehicles and smart
											homes.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={MLOps} alt="data-science" />
									</div>
									<div className="text">
										<h3>MLOps</h3>
										<p>
											The performance of machine learning models may change with the changes in the distribution of data with time. MLOps ensures continuous model monitoring , drift detection and adaptation of the model to the new changes in the data. Our MLOps engineers help enterprises in  automating and standardizing processes across the ML life cycle which helps to easily train , test, troubleshoot, deploy and govern ML models. This boosts the productivity of data scientists and ML engineers while maintaining model performance in production.
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<Process />
					<Contact />
				</div>
			</div>
		</>
	);
}

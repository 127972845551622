import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import Healthcare from "../../img/industries/healthcare.jpg";
import Finance from "../../img/industries/finance.jpg";
import Retail from "../../img/industries/retail.jpg";
import Insurance from "../../img/industries/insurance.jpg";
import Government from "../../img/industries/government.jpg";
import Education from "../../img/industries/education.jpg";

export default function Industries() {

	const [heading, setHeading] = useState('');
	const { pathname } = useLocation();

	useEffect(() => {
		if (pathname.includes('/company')) {
			setHeading('Industries and Verticals Served');
		} else {
			setHeading('Featured Industries');
		}
	}, [pathname]);

	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `<button type="button" class="btn ${className}">
						${index === 0
					? '<span><i class="fa-light fa-heart-pulse pe-1"></i> Healthcare</span>'
					: index === 1
						? '<span><i class="fa-light fa-icons pe-1"></i> Finance</span>'
						: index === 2
							? '<span><i class="fa-light fa-cart-shopping-fast pe-1"></i> Retail</span>'
							: index === 3
								? '<span><i class="fa-light fa-credit-card pe-1"></i> Insurance</span>'
								: index === 4
									? '<span><i class="fa-light fa-lightbulb-on pe-1"></i> Government</span>'
									: index === 5
										? '<span><i class="fa-light fa-graduation-cap pe-1"></i> Education</span>'
										: ""
				}
					</button>`;
		},
	};
	return (
		<section className="industries">
			<div className="codex-container">
				<div className="section-title" data-aos="fade-up">
					<h2>{heading}</h2>
					<p>
						We are a full-service custom software development company, providing technical expertise to meet
						the digital needs of an enterprise across various industries.
					</p>
				</div>
				<Row data-aos="fade-up" data-aos-delay="100">
					<Col lg={12}>
						<div className="slides-content">
							<Swiper
								spaceBetween={30}
								slidesPerView={1}
								pagination={pagination}
								modules={[Pagination, Autoplay]}
								loop
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
									pauseOnMouseEnter: true
								}}
							>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center justify-content-center">
											<Col lg="6">
												<img
													src={Healthcare}
													alt="healthcare"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Healthcare</h4>
												<p>
													With a wide range of customized software development services , CodexNow offers unique solutions for
													healthcare organizations in the digital landscape ranging from simple data collection applications
													to support various business processes to advanced AI based diagnostic & analytical applications and
													smart healthcare devices.
												</p>
												<Link to="/sectors#healthcare" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center">
											<Col lg="6">
												<img
													src={Finance}
													alt="finance"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Finance</h4>
												<p>
													CodexNow specializes in helping the financial institutions in developing customized solutions to
													automate financial workflows , revolutionize traditional banking models , secure and optimize data
													management , automate the analytical workflows and data pipelines with the help of its smart and AI
													based technological innovations.
												</p>
												<Link to="/sectors#finance" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center">
											<Col lg="6">
												<img
													src={Retail}
													alt="retail"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Retail</h4>
												<p>
													At codexNow , we design and develop a complete range of retail and e-commerce solutions to support
													POS operations , online transactions, warehouse and store management that help businesses reduce
													operational costs and increase profitability. We develop AI based systems that help in shelf
													management , inventory optimization and continuous product improvement.
												</p>
												<Link to="/sectors#retail" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center">
											<Col lg="6">
												<img
													src={Insurance}
													alt="insurance"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Insurance</h4>
												<p>
													The Data Analytics team at CodexNow provides 360 degree support to develop analytical applications
													for the insurance industry covering all aspects including life , medical , auto and home insurance .
													Our services range from providing consultancy for analytical use cases , requirement analysis and
													POC to complete AI based analytical solutions with 24/7 support.
												</p>
												<Link to="/sectors#insurance" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center">
											<Col lg="6">
												<img
													src={Government}
													alt="government"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Government</h4>
												<p>
													We provide services to the government sector in developing customized software solutions to
													efficiently manage their business processes , development work and ongoing projects. We build
													systems that help to manage different stakeholders in a project such as project owner , client,
													contractors, sub-contractors , in-house staff , workers and managers etc.
												</p>
												<Link to="/sectors#government" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
								<SwiperSlide>
									<div className="slide">
										<Row className="gx-5 align-items-center">
											<Col lg="6">
												<img
													src={Education}
													alt="education"
													className="img-fluid rounded"
												/>
											</Col>
											<Col lg="6">
												<h4>Education</h4>
												<p>
													CodexNow offers a wide range of development and analytical services to transform and optimize the
													workflows in educational institutions . We provide necessary services for digital transformation of
													educational institutions as well as essential analytical services assisting in curriculum
													development , adaptive learning and course & faculty recommendations.
												</p>
												<Link to="/sectors#education" className="btn main-btn">
													View More Details
												</Link>
											</Col>
										</Row>
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
}

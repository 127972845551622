import React, { useState } from "react";
import { Row, Col, InputGroup, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../../img/codexlogo.png';
import LogoWhite from "../../img/codexlogo-w.png";
import axios from 'axios';

export default function Footer() {
	const [loader, setLoader] = useState(false);
	const [response, setResponse] = useState(null);

	const [data, setData] = useState({
		email: '',
		type: 'subscription'

	})

	let config = {
		headers: {
			"Content-Type": "application/json"
		}
	}

	const submitSubscriptionForm = (e) => {
		e.preventDefault();
		setLoader(true);
		setResponse(null)
		axios.post(`https://codexnow.com/contact/contactus`, data, config)
			.then(res => {
				console.log(res);
				if (res.status === 200) {
					setLoader(false);
					setResponse('Email Subscription Successfull!')
				}
			}).catch(function (error) {
				console.log(error);
				setLoader(false);
				setResponse('Please try again!')
			});
	};

	return (
		<footer className="footer">
			<div className="codex-container">
				<Row className="mb-5 gx-5">
					<Col xl="3" md="12">
						<div className="logo">
							<Link to="/">
								<img src={document.body.classList.contains('dark') ? LogoWhite : Logo} alt="logo" width="150" height="42" />
							</Link>
						</div>
						<p>
							Our growing team is composed of Researchers, Doctors, Engineers & Market Experts with a broad spectrum of technology and spans the globe with headquarters in California and offices around the world.
						</p>
						<div className="social-links my-4">
							<a href="https://twitter.com/CodeXnow" target="_blank" rel="noreferrer" className="social-link btn">
								<i className="fa-brands fa-twitter"></i>
							</a>
							<a
								href="https://www.facebook.com/WeAreCodeXnow/"
								target="_blank"
								rel="noreferrer"
								className="social-link btn"
							>
								<i className="fa-brands fa-facebook"></i>
							</a>
							<a
								href="https://www.instagram.com/codexnow/"
								target="_blank"
								rel="noreferrer"
								className="social-link btn"
							>
								<i className="fa-brands fa-instagram"></i>
							</a>
							<a
								href="https://www.linkedin.com/company/codexit"
								target="_blank"
								rel="noreferrer"
								className="social-link btn"
							>
								<i className="fa-brands fa-linkedin"></i>
							</a>
						</div>
					</Col>
					<Col xxl={{ span: 2, offset: 1 }} xl="3" md="4" className="mt-4 mt-xl-0">
						<h5>Engineering Services</h5>
						<ul>
							<li>
								<Link to="/services/engineering#software-engineering">Software Engineering</Link>
							</li>
							<li>
								<Link to="/services/engineering#product-development">Product Development</Link>
							</li>
							<li>
								<Link to="/services/engineering#data-engineering">Data Engineering</Link>
							</li>
							<li>
								<Link to="/services/engineering#requirements-engineering">Requirements Engineering</Link>
							</li>
						</ul>
					</Col>
					<Col xxl="2" xl="3" md="4" className="mt-4 mt-xl-0">
						<h5>Data Science / AI</h5>
						<ul>
							<li>
								<Link to="/services/data-science#business-intelligence">Business Intelligence</Link>
							</li>
							<li>
								<Link to="/services/data-science#machine-learning">Machine Learning</Link>
							</li>
							<li>
								<Link to="/services/data-science#deep-learning">Deep Learning</Link>
							</li>
							<li>
								<Link to="/services/data-science#timeseries-forecasting">Timeseries Forecasting</Link>
							</li>
						</ul>
					</Col>
					<Col xxl="2" xl="3" md="4" className="mt-4 mt-xl-0">
						<h5>Design</h5>
						<ul>
							<li>
								<Link to="/services/design#ux-ui-design">UX / UI Design</Link>
							</li>
							<li>
								<Link to="/services/design#logo-design">Logo Design</Link>
							</li>
							<li>
								<Link to="/services/design#website-design">Website Design</Link>
							</li>
							<li>
								<Link to="/services/design#frontend-development">Frontend Development</Link>
							</li>
						</ul>
					</Col>
					<Col md="12">
						<span className="fw-bold subscribe-text">Always Remain in Sync with Codex</span>
						<Form onSubmit={(e) => submitSubscriptionForm(e)}>
							<InputGroup>
								<Form.Control placeholder="Enter Email Address" name="email" required onChange={(e) =>
									setData({
										...data,
										[e.target.name]: e.target.value,
									})
								} />
								<Button type="submit" className="main-btn" disabled={loader ? true : false}>
									{loader ? <Spinner animation="border" size="sm" /> : 'Subscribe'}
								</Button>
							</InputGroup>
							{response != null ? <p>{response}</p> : null}
						</Form>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="copyright">
							Copyright &copy;
							<strong>
								<span>
									<Link to="/"> Codex</Link>
								</span>
							</strong>
							. All Rights Reserved{" "}
						</div>
					</Col>
				</Row>
			</div>
		</footer>
	);
}

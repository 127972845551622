import React from "react";
import { Row, Col } from "react-bootstrap";
import Process from "./Process";
import Contact from "./homepage/Contact";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export default function Services() {

	return (
		<>
			<div className="main-content">
				<div className="content-header header-bg">
					<div className="codex-container">
						<Row>
							<Col lg="12">
								<div className="header-text">
									<h2>Services</h2>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="content pt-0">
					<div className="codex-container">
						<Row className="gx-5 align-items-center service-row justify-content-between" id="engineering">
							<Col xl={5}>
								<h2 className="content-h" data-aos="fade-up">
									Engineering Services
								</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p" data-aos="fade-up" data-aos-delay="100">
									CodexNow provides on-demand engineering services in numerous domains to fulfill its customer needs.
									With dedicated teams, we ensure high quality work focusing on customer requirements. We specialize
									in providing engineering services in software development , product development , big data
									engineering , requirements engineering , usability and quality engineering and DevOps. Browse
									through our <Link to='/services/engineering'>engineering services</Link> below for more details or see the{" "}
									<HashLink to="/services/#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<div className="service-overview software" data-aos="fade-up">
											<h4>Software Engineering</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview product" data-aos="fade-up">
											<h4>Product Development</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview data" data-aos="fade-up" data-aos-delay="100">
											<h4>Data Engineering</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview requirement" data-aos="fade-up" data-aos-delay="100">
											<h4>Requirements Engineering</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview usability" data-aos="fade-up" data-aos-delay="100">
											<h4>Usability Engineering</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview quality-testing" data-aos="fade-up" data-aos-delay="200">
											<h4>Quality Engineering & Testing</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview devops" data-aos="fade-up" data-aos-delay="200">
											<h4>DevOps</h4>
										</div>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
						<Row className="gx-5 align-items-center service-row justify-content-between" id="data-science">
							<Col xl={5}>
								<h2 className="content-h" data-aos="fade-up">
									Data Science and AI
								</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p" data-aos="fade-up" data-aos-delay="100">
									At CodexNow , you will find professional teams of data scientists , subject matter experts , machine
									learning engineers , data engineers , usability and quality engineers working together to build
									customized AI based solutions . We help enterprises in every stage of the data science life cycle
									including requirements engineering , data collection , data cleaning and pres-processing ,
									exploratory data analysis , model training , deployment and maintenance. We provide a complete range
									of services starting from POC to full scale deployment. Browse through our <Link to="/services/data-science">Data Science and AI
										services</Link> for more details or see <HashLink to="/services/#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<div className="service-overview business-intelligence" data-aos="fade-up">
											<h4>Business Intelligence</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview machine-learning" data-aos="fade-up">
											<h4>Machine Learning</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview deep-learning" data-aos="fade-up" data-aos-delay="100">
											<h4>Deep Learning</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview timeseries-forecasting" data-aos="fade-up" data-aos-delay="100">
											<h4>Timeseries Forecasting</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview iot" data-aos="fade-up" data-aos-delay="100">
											<h4>Internet of Things (IoT)</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview mlops" data-aos="fade-up" data-aos-delay="200">
											<h4>MLOps</h4>
										</div>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
						<Row className="gx-5 align-items-center service-row justify-content-between" id="design">
							<Col xl={5}>
								<h2 className="content-h" data-aos="fade-up">
									Design Services
								</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p" data-aos="fade-up" data-aos-delay="100">
									CodexNow helps organizations to distinctly stand in the digital world . We provide exceptional
									design services in creating innovative and user friendly UX/UI , logos , websites and user
									interfaces for conventional software applications as well as modern AI based systems. Our dedicated
									teams of UX/UI designers and usability experts work closely with the customer to get out of the box
									design solutions that help them stay digitally distinct with improved user experience. Browse
									through our <Link to="/services/design">Design Services</Link> for more details or see{" "}
									<HashLink to="/services/#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 2500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<div className="service-overview ux-ui" data-aos="fade-up">
											<h4>UX / UI Design</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview logo" data-aos="fade-up">
											<h4>Logo Design</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview website" data-aos="fade-up" data-aos-delay="100">
											<h4>Website Design</h4>
										</div>
									</SwiperSlide>
									<SwiperSlide>
										<div className="service-overview frontend" data-aos="fade-up" data-aos-delay="100">
											<h4>Frontend Development</h4>
										</div>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
					</div>
					<Process />
					<Contact />
				</div>
			</div>
		</>
	);
}

import React from "react";
import { Row, Col } from "react-bootstrap";
import Testimonials from "./homepage/Testimonials";
import Industries from "./homepage/Industries";
import Contact from "./homepage/Contact";

import Mission from "../img/mission.jpg";
import Innovation from "../img/innovation.jpg";
import ResearchLab from "../img/research-lab.jpg";

export default function AboutUs() {
	return (
		<>
			<div className="main-content">
				<div className="content-header header-bg">
					<div className="codex-container">
						<Row>
							<Col lg="12">
								<div className="header-text">
									<h2>About Us</h2>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="content">
					<section className="with-bg">
						<div className="codex-container">
							<Row className="gx-5 align-items-center justify-content-between" data-aos="fade-up">
								<Col lg={6} className="mb-5 mb-lg-0 mt-5 mt-md-0">
									<h2 className="content-h">Our Mission</h2>
									<h3 className="quotes">
										<span className="pe-3">
											<i className="fa-solid fa-quote-left"></i>
										</span>
										To help companies develop competitiveness and agility using high quality software design and
										implementation{" "}
										<span className="ps-3">
											<i className="fa-solid fa-quote-right"></i>
										</span>
									</h3>
									<p className="content-p mt-4">
										We're a tech company with a mission to help successful companies extend their tech capacity. Founded
										in 2015, we have helped organizations ranging from Startups that went public, to Fortune 500
										companies, and WHO featured NGOs.
									</p>
								</Col>
								<Col xl={5} lg={6}>
									<img src={Mission} alt="mission" className="img-fluid rounded content-img bottom" />
								</Col>
							</Row>
						</div>
					</section>
					<section>
						<div className="codex-container">
							<Row
								className="gx-5 align-items-center justify-content-between flex-row flex-lg-row-reverse"
								data-aos="fade-up"
							>
								<Col lg={6} className="mb-5 mb-lg-0">
									<h2 className="content-h">We Are Innovators</h2>
									<ul className="content-ul">
										<li>
											We leverage our broad knowledge base to deliver solutions that meet customer's needs, business, and
											budget expectations. When you choose CodeX, know that your business is in the right hands.
										</li>
										<li>
											Our growing team of 50+ technology spans the globe with headquarter in California and offices around
											the world.
										</li>
										<li>
											CodeX helps companies become innovation leaders by delivering software teams on demand. Our teams
											help you decide the right architecture and processes to follow and oversee the successful delivery
											of your software projects.
										</li>
									</ul>
								</Col>
								<Col xl={5} lg={6}>
									<img src={Innovation} alt="mission" className="img-fluid rounded content-img" />
								</Col>
							</Row>
						</div>
					</section>
					<section className="with-bg">
						<div className="codex-container">
							<Row className="gx-5 align-items-center justify-content-between" data-aos="fade-up">
								<Col lg={6} className="mb-5 mb-lg-0">
									<h2 className="content-h">We believe in active research</h2>
									<p>
										Having roots in Research & Innovation, Codex is always involved in Research & Development of
										products and technologies that can generate reliable and adaptable solutions to the challenges faced
										by industries of today and tomorrow. In CodeX Lab, we envision the next technology, bring concepts
										and ideas into reality for our partners and clients. Our Lab is always open to discuss challenging
										issues faced by companies and proposes solutions to that. At CodeX Lab, we study, research, innovate
										and design solutions in Artificial Intelligence, Machine Learning, Deep Learning, Cryptography,
										Virtual Reality, Blockchain, Natural Language Processing, Data Driven Business Models and
										Strategies, Digital Footprint Analysis and HealthCare etc.
									</p>
								</Col>
								<Col xl={5} lg={6}>
									<img src={ResearchLab} alt="mission" className="img-fluid rounded content-img" />
								</Col>
							</Row>
						</div>
					</section>
					<Industries />
					<Testimonials />
					<Contact />
				</div>
			</div>
		</>
	);
}

import { Link } from "react-router-dom";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

// import MachineLearning from "../../img/services/machine-learning.png";
// import DeepLearning from "../../img/services/deep-learning.png";
// import BigData from "../../img/services/big-data.png";
// import BusinessIntelligence from "../../img/services/business-intelligence.png";
// import TimeSeries from "../../img/services/time-series.png";
// import WebDesign from "../../img/services/web-design.png";
// import AppDevelopment from "../../img/services/app-development.png";
// import LogoDesign from "../../img/services/logo.png";
// import SoftwareDevelopment from "../../img/services/software-development.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default function Services() {
	return (
		<section className="services with-bg" id="services">
			<div className="codex-container">
				<div className="section-title mb-5">
					<h2>Our Services</h2>
					<p>
						Codex is a one-stop solution for custom software development needs of any modern digital enterprise. We provide 360 degree services in concept
						analysis, product design and development, fintech solutions and AI & machine learning based analytical systems.
					</p>
				</div>
				{/* <Row className="gx-5">
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#data-science">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={MachineLearning} alt="services" />
                                </div>
                                <h4>
                                    Machine Learning <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Machine learning services include business use case and data requirements analysis, POC implementation and analytical product development... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#data-science">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={DeepLearning} alt="services" />
                                </div>
                                <h4>
                                    Deep Learning <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    With its deep learning experts and R&D team , codex help enterprises in solving computer vision problems through image and video analysis in varied domains... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#data-science">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={BigData} alt="services" />
                                </div>
                                <h4>
                                    Big Data Analytics <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    You can leverage the consultancy and development services of the BigData Analytics team to scale your storage , processing and analytical infrastructure... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#data-science">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={BusinessIntelligence} alt="services" />
                                </div>
                                <h4>
                                    Business Intelligence & DWH <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Codex BI & DWH team provides its services to help enterprises in starting their analytical journey by building a data warehouse and BI dashboards... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#data-science">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={TimeSeries} alt="services" />
                                </div>
                                <h4>
                                    Timeseries Forecasting <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Train , build and deploy statistical and deep learning based time series models to solve your univariate and multivariate problems in finance , weather forecasting , health , energy and other domains... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#design">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={WebDesign} alt="services" />
                                </div>
                                <h4>
                                    Web Design & Development <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Our experienced UX/ UI team creates visually compelling web app experiences that are streamlined and engaging so they attract and retain users... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#engineering">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={AppDevelopment} alt="services" />
                                </div>
                                <h4>
                                    Mobile Application Development <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    We provide comprehensive mobile application development services to build and deploy reliable and secure mobile applications to support your business operations... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#design">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={LogoDesign} alt="services" />
                                </div>
                                <h4>
                                    Logo Design <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Codex helps businesses in converting their ideas into attractive and innovative designs that help them stand uniquely in the digital world... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xxl={4} lg={6} className="mb-6">
                        <Link to="/services#engineering">
                            <div className="services-box">
                                <div className="icon">
                                    <img src={SoftwareDevelopment} alt="services" />
                                </div>
                                <h4>
                                    Software Development <i className="fa-regular fa-arrow-right-long"></i>
                                </h4>
                                <p>
                                    Codex provides 360 degree services in developing and deploying custom software applications in healthcare , finance , insurance , education and other domains... <span>read more</span>
                                </p>
                            </div>
                        </Link>
                    </Col>
                </Row> */}
				<div className="main-content mt-0">
					<div className="content pt-0">
						<Row className="gx-5 align-items-center service-row justify-content-between" id="engineering">
							<Col xl={5}>
								<h2 className="content-h">Engineering Services</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p">
									CodexNow provides on-demand engineering services in numerous domains to fulfill its customer needs. With dedicated teams, we ensure high quality work
									focusing on customer requirements. We specialize in providing engineering services in software development , product development , big data
									engineering , requirements engineering , usability and quality engineering and DevOps. Browse through our{" "}
									<Link to="/services/engineering">engineering services</Link> below for more details or see the{" "}
									<HashLink to="#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 3500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview software">
												<h4>Software Engineering</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview product">
												<h4>Product Development</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview data">
												<h4>Data Engineering</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview requirement">
												<h4>Requirements Engineering</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview usability">
												<h4>Usability Engineering</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview quality-testing">
												<h4>Quality Engineering & Testing</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/engineering">
											<div className="service-overview devops">
												<h4>DevOps</h4>
											</div>
										</Link>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
						<Row className="gx-5 align-items-center service-row justify-content-between" id="data-science">
							<Col xl={5}>
								<h2 className="content-h">Data Science and AI</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p">
									At CodexNow , you will find professional teams of data scientists , subject matter experts , machine learning engineers , data engineers , usability
									and quality engineers working together to build customized AI based solutions . We help enterprises in every stage of the data science life cycle
									including requirements engineering , data collection , data cleaning and pres-processing , exploratory data analysis , model training , deployment and
									maintenance. We provide a complete range of services starting from POC to full scale deployment. Browse through our{" "}
									<Link to="/services/data-science">Data Science and AI services</Link> for more details or see{" "}
									<HashLink to="#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 3500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview business-intelligence">
												<h4>Business Intelligence</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview machine-learning">
												<h4>Machine Learning</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview deep-learning">
												<h4>Deep Learning</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview timeseries-forecasting">
												<h4>Timeseries Forecasting</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview iot">
												<h4>Internet of Things (IoT)</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/data-science">
											<div className="service-overview mlops">
												<h4>MLOps</h4>
											</div>
										</Link>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
						<Row className="gx-5 align-items-center service-row justify-content-between pb-0" id="design">
							<Col xl={5}>
								<h2 className="content-h">Design Services</h2>
							</Col>
							<Col xl={7}>
								<p className="content-p">
									CodexNow helps organizations to distinctly stand in the digital world . We provide exceptional design services in creating innovative and user
									friendly UX/UI , logos , websites and user interfaces for conventional software applications as well as modern AI based systems. Our dedicated teams
									of UX/UI designers and usability experts work closely with the customer to get out of the box design solutions that help them stay digitally distinct
									with improved user experience. Browse through our <Link to="/services/design">Design Services</Link> for more details or see{" "}
									<HashLink to="#process">Customer Engagement Process</HashLink>
								</p>
							</Col>
							<Col lg={12} className="mt-5">
								<Swiper
									spaceBetween={40}
									slidesPerView="auto"
									autoplay={{
										delay: 3500,
										disableOnInteraction: false,
									}}
									loop
									navigation={true}
									modules={[Autoplay, Navigation]}
									breakpoints={{
										768: {
											slidesPerView: 2,
										},
										1024: {
											slidesPerView: 3,
										},
										1400: {
											slidesPerView: 4,
										},
									}}
									className="services-swiper"
								>
									<SwiperSlide>
										<Link to="/services/design">
											<div className="service-overview ux-ui">
												<h4>UX / UI Design</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/design">
											<div className="service-overview logo">
												<h4>Logo Design</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/design">
											<div className="service-overview website">
												<h4>Website Design</h4>
											</div>
										</Link>
									</SwiperSlide>
									<SwiperSlide>
										<Link to="/services/design">
											<div className="service-overview frontend">
												<h4>Frontend Development</h4>
											</div>
										</Link>
									</SwiperSlide>
								</Swiper>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</section>
	);
}

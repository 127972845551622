import React from "react";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export default function Process() {
	return (
		<div className="codex-container">
			<Row className="gx-5 align-items-center" id="process">
				<Col xl={4} lg={5} data-aos="fade-up">
					<div className="process">
						<h4>Our Client On-Boarding Process</h4>
					</div>
				</Col>
				<Col xl={8} lg={7} data-aos="fade-up" data-aos-delay="100">
					<div className="process-slides">
						<Swiper
							speed={800}
							spaceBetween={100}
							slidesPerView={1}
							navigation={true}
							modules={[Navigation, Autoplay]}
							loop
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
								pauseOnMouseEnter: true
							}}
						>
							<SwiperSlide>
								<div className="slide">
									<div className="icon">
										<i className="fa-regular fa-lightbulb-exclamation-on"></i>
									</div>
									<div className="text">
										<h4>Your Thoughts</h4>
										<p>
											Place your inquiry in the form below. Our business development executive from concerned product
											department will reach you for further communication within few hours.
										</p>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="icon">
										<i className="fa-regular fa-file-pen"></i>
									</div>
									<div className="text">
										<h4>NDA</h4>
										<p>
											Your idea is important to us and we ensure it&apos;s security. <br />
											Our NDA ensures that your idea and project belongs to you only.
										</p>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="icon">
										<i className="fa-regular fa-chart-mixed"></i>
									</div>
									<div className="text">
										<h4>Requirement Analysis</h4>
										<p>Our technical team will analyze the requirement and will get back to you within few hours.</p>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="icon">
										<i className="fa-regular fa-timer"></i>
									</div>
									<div className="text">
										<h4>Time & Cost Estimation</h4>
										<p>
											Once your project is analyzed and resources are aligned, we will get back to you with Estimated Cost
											and Required Time for project delivery.
										</p>
									</div>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</Col>
			</Row>
		</div>
	);
}

import React from "react";
import "swiper/css";

export default function Hero() {
	return (
		<div className="hero">
			<div className="position-relative h-100">
				<div className="hero-text dream">
					<span>Dream</span>
				</div>
				<div className="hero-text concept">
					<span>Concept</span>
				</div>
				<div className="hero-text design">
					<span>Design</span>
				</div>
				<div className="hero-text product">
					<span>Product</span>
				</div>
			</div>
		</div>
	);
}

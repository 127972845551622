import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/general/ScrollToTop";
import Header from "./components/general/Header";
import Footer from "./components/general/Footer";
import Homepage from "./components/homepage/Homepage";
import Services from "./components/Services";
import Sectors from "./components/Sectors";
import Technologies from "./components/Technologies";
import Portfolio from "./components/Portfolio";
import AboutUs from "./components/AboutUs";

import AOS from "aos";
import "aos/dist/aos.css";
import Engineering from "./components/services/Engineering";
import DataScience from "./components/services/Data-Science";
import Design from "./components/services/Design";

function App() {
	useEffect(() => {
		AOS.init({
			duration: 800,
			once: true,
		});
	}, []);
	return (
		<Router>
			<div className="codex">
				<ScrollToTop />
				<Header />
				<Routes>
					<Route path="/" element={<Homepage />} />
					<Route path="/services" element={<Services />} />
					<Route path="/services">
						<Route index element={<Services />} />
						<Route path="engineering" element={<Engineering />} />
						<Route path="data-science" element={<DataScience />} />
						<Route path="design" element={<Design />} />
					</Route>
					<Route path="/sectors" element={<Sectors />} />
					<Route path="/technologies" element={<Technologies />} />
					<Route path="/portfolio" element={<Portfolio />} />
					<Route path="/about-us" element={<AboutUs />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;

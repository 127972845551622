import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            var hash_string = hash.split('#');
            var offsets = document.getElementById(`${hash_string[1]}`).getBoundingClientRect();
            window.scrollTo(0, offsets.top + window.pageYOffset - 150);
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            });
        }
    }, [pathname, hash]);

    return null;
}
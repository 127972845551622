import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import axios from 'axios';

export default function Contact() {

	const [loader, setLoader] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseType, setResponseType] = useState(null);
	const [alert, setAlert] = useState(false);

	const [data, setData] = useState({
		name: '',
		email: '',
		phone_no: '',
		subject: '',
		message: '',
		budget: '',
		company: '',
		type: 'contactus'
	})

	let config = {
		headers: {
			"Content-Type": "application/json"
		}
	}

	const submitContactForm = (e) => {
		e.preventDefault();
		setLoader(true);
		setAlert(false);
		axios.post(`https://codexnow.com/contact/contactus`, data, config)
			.then(res => {
				console.log(res);
				if (res.status === 200) {
					setAlert(true);
					setLoader(false);
					setResponse('Your message has been sent. Thank you!')
					setResponseType('success')
				}
			}).catch(function (error) {
				console.log(error);
				setLoader(false);
				setAlert(true);
				setResponse('Please try again!')
				setResponseType('danger')
			});
	};

	return (
		<section className="contact enquire" id="contact">
			<div className="codex-container">
				<div className="section-title" data-aos="fade-up">
					<h2>Need a Technological Solution?</h2>
					<h3>Contact Us</h3>
				</div>
				<Row className="gx-5 justify-content-evenly align-items-center">
					<Col xxl={5} lg={6} data-aos="fade-up" data-aos-delay="100">
						<Form onSubmit={(e) => submitContactForm(e)} className="contact-form mb-4 mb-lg-0">
							<Form.Group className="mb-3 row text-start">
								<Col sm="6">
									<Form.Control type="text" placeholder="Name*" name="name" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
								<Col sm="6" className="mt-3 mt-sm-0">
									<Form.Control type="text" placeholder="Company*" name="company" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
							</Form.Group>
							<Form.Group className="mb-3 row text-start">
								<Col sm="6">
									<Form.Control type="email" placeholder="Work Email*" name="email" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
								<Col sm="6" className="mt-3 mt-sm-0">
									<Form.Control type="tel" placeholder="Phone*" name="phone_no" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
							</Form.Group>
							<Form.Group className="mb-3 row text-start">
								<Col sm="6">
									<Form.Control type="text" placeholder="Subject*" name="subject" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
								<Col sm="6" className="mt-3 mt-sm-0">
									<Form.Control type="text" placeholder="Project Budget*" name="budget" required onChange={(e) =>
										setData({
											...data,
											[e.target.name]: e.target.value,
										})
									} />
								</Col>
							</Form.Group>
							<Form.Group className="mb-3 text-start">
								<Form.Control as="textarea" placeholder="Message*" name="message" required onChange={(e) =>
									setData({
										...data,
										[e.target.name]: e.target.value,
									})
								} />
							</Form.Group>
							{alert ?
								<Alert variant={responseType} onClose={() => setAlert(false)} dismissible>
									{response}
								</Alert>

								: null}
							<div className="d-flex flex-column flex-xl-row justify-content-between align-items-center mt-5">
								<p className="order-2 order-xl-1">
									Please be informed that when you click the Send button Codex will process your personal data in
									accordance with our Privacy Policy for the purpose of providing you with appropriate information.
								</p>
								<Button type="submit" className="main-btn order-1 order-xl-2 mb-3 mb-xl-0" disabled={loader ? true : false}>
									{loader ? <Spinner animation="border" size="sm" /> : 'Send Message'}
								</Button>
							</div>
						</Form>
					</Col>
					<Col xxl={5} lg={6} data-aos="fade-up" data-aos-delay="100">
						<div className="steps">
							<h3>What happens next?</h3>
							<ul>
								<li>
									Having received and processed your request, we will get back to you shortly to detail your project
									needs and sign an NDA to ensure the confidentiality of information.
								</li>
								<li>
									After examining requirements, our analysts and developers devise a project proposal with the scope
									of works, team size, time, and cost estimates.
								</li>
								<li>We arrange a meeting with you to discuss the offer and come to an agreement.</li>
								<li>We sign a contract and start working on your project as quickly as possible.</li>
							</ul>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
}

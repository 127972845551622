import React from "react";
import { Row, Col } from "react-bootstrap";

import Healthcare from "../img/industries/healthcare.jpg";
import Finance from "../img/industries/finance.jpg";
import Retail from "../img/industries/retail.jpg";
import Insurance from "../img/industries/insurance.jpg";
import Government from "../img/industries/government.jpg";
import Education from "../img/industries/education.jpg";

export default function Sectors() {
	return (
		<>
			<div className="main-content">
				<div className="content-header header-bg">
					<div className="codex-container">
						<Row>
							<Col lg="12">
								<div className="header-text">
									<h2>Sectors</h2>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="content pt-0">
					<section className="with-bg">
						<div className="codex-container">
							<Row className="gx-5 align-items-center justify-content-center" id="healthcare" data-aos="fade-up">
								<Col xl={4} lg={6}>
									<h2 className="content-h">Healthcare</h2>
									<p className="content-p">
										With a wide range of customized software development services , CodexNow offers unique solutions for
										healthcare organizations in the digital landscape ranging from simple data collection applications
										to support various business processes to advanced AI based diagnostic & analytical applications and
										smart healthcare devices.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>Hospital Management Software</li>
										<li>Healthcare CRM</li>
										<li>Medical Image Analysis</li>
										<li>Medical Data Analysis</li>
										<li>Medical Device Software Development</li>
										<li>Pharmacy Management Software</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Healthcare} alt="healthcare" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
					<section>
						<div className="codex-container">
							<Row
								className="gx-5 align-items-center justify-content-center flex-row flex-xl-row-reverse"
								id="finance"
								data-aos="fade-up"
							>
								<Col xl={4} lg={6}>
									<h2 className="content-h">Finance</h2>
									<p className="content-p">
										CodexNow specializes in helping the financial institutions in developing customized solutions to
										automate financial workflows , revolutionize traditional banking models , secure and optimize data
										management , automate the analytical workflows and data pipelines with the help of its smart and AI
										based technological innovations.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>Banking Software</li>
										<li>Mobile Banking</li>
										<li>Blockchain</li>
										<li>Banking CRM</li>
										<li>Stock Trading Software</li>
										<li>Financial Data Analytics</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Finance} alt="finance" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
					<section className="with-bg">
						<div className="codex-container">
							<Row className="gx-5 align-items-center justify-content-center" id="retail" data-aos="fade-up">
								<Col xl={4} lg={6}>
									<h2 className="content-h">Retail</h2>
									<p className="content-p">
										At codexNow , we design and develop a complete range of retail and e-commerce solutions to support
										POS operations , online transactions, warehouse and store management that help businesses reduce
										operational costs and increase profitability. We develop AI based systems that help in shelf
										management , inventory optimization and continuous product improvement.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>POS Systems</li>
										<li>eCommerce Solutions</li>
										<li>Customer Experience</li>
										<li>Retail ERP</li>
										<li>Retail CRM</li>
										<li>Retail Data Analytics</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Retail} alt="retail" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
					<section>
						<div className="codex-container">
							<Row
								className="gx-5 align-items-center justify-content-center flex-row flex-xl-row-reverse"
								id="insurance"
								data-aos="fade-up"
							>
								<Col xl={4} lg={6}>
									<h2 className="content-h">Insurance</h2>
									<p className="content-p">
										The Data Analytics team at CodexNow provides 360 degree support to develop analytical applications
										for the insurance industry covering all aspects including life , medical , auto and home insurance .
										Our services range from providing consultancy for analytical use cases , requirement analysis and
										POC to complete AI based analytical solutions with 24/7 support.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>Use Case Analysis</li>
										<li>POC</li>
										<li>Data Governance / Data Quality Assessment</li>
										<li>Cross / Up Sell Use Case</li>
										<li>Insurance Fraud Detection CRM</li>
										<li>Insurance Management System</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Insurance} alt="insurance" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
					<section className="with-bg">
						<div className="codex-container">
							<Row className="gx-5 align-items-center justify-content-center" id="government" data-aos="fade-up">
								<Col xl={4} lg={6}>
									<h2 className="content-h">Government</h2>
									<p className="content-p">
										We provide services to the government sector in developing customized software solutions to
										efficiently manage their business processes , development work and ongoing projects. We build
										systems that help to manage different stakeholders in a project such as project owner , client,
										contractors, sub-contractors , in-house staff , workers and managers etc.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>Project Management System</li>
										<li>Project Management & Information System</li>
										<li>Management Information Systems</li>
										<li>Procurement Management System</li>
										<li>Stakeholder Management System</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Government} alt="government" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
					<section>
						<div className="codex-container">
							<Row
								className="gx-5 align-items-center justify-content-center flex-row flex-xl-row-reverse"
								id="education"
								data-aos="fade-up"
							>
								<Col xl={4} lg={6}>
									<h2 className="content-h">Education</h2>
									<p className="content-p">
										CodexNow offers a wide range of development and analytical services to transform and optimize the
										workflows in educational institutions . We provide necessary services for digital transformation of
										educational institutions as well as essential analytical services assisting in curriculum
										development , adaptive learning and course & faculty recommendations.
									</p>
								</Col>
								<Col xl={3} lg={6}>
									<ul className="content-ul mt-0">
										<li>Online Learning Platform</li>
										<li>Learning Management System</li>
										<li>ERP</li>
										<li>Website Design</li>
										<li>Self Paced Learning Systems</li>
										<li>Analytical Use Cases</li>
									</ul>
								</Col>
								<Col xl={4} lg={6} className="mt-5 mt-xl-0">
									<img src={Education} alt="education" className="rounded" height={256} />
								</Col>
							</Row>
						</div>
					</section>
				</div>
			</div>
		</>
	);
}

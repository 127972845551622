import React from "react";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

// import EFULife from "../../img/testimonials/efu-life.png";
// import Sindh from "../../img/testimonials/sindh.png";
import FROME from "../../img/testimonials/frome.png";
import BRYT from "../../img/testimonials/bryt.png";
import SDG from "../../img/testimonials/sdg.png";
import NEFT from "../../img/testimonials/neft.png";
import SaveDaily from "../../img/testimonials/save-daily.png";

export default function Testimonials() {
	return (
		<section className="testimonials with-bg">
			<div className="codex-container">
				<div className="section-title" data-aos="fade-up">
					<h2>Our Clients</h2>
					<p>
						Since our inception in 2015, we have helped organizations ranging from Startups that went public to Fortune 500 companies, and WHO-featured NGOs in
						meeting their development, technological and analytical needs.
					</p>
				</div>
				<Row data-aos="fade-up" data-aos-delay="100">
					<Col lg="12">
						<Swiper
							spaceBetween={30}
							slidesPerView="auto"
							navigation={true}
							modules={[Navigation, Autoplay]}
							loop
							autoplay={{
								delay: 2500,
								disableOnInteraction: false,
							}}
							breakpoints={{
								576: {
									slidesPerView: 1,
								},
								768: {
									slidesPerView: 2,
								},
								1024: {
									slidesPerView: 3,
								},
								1400: {
									slidesPerView: 4,
								},
							}}
						>
							{/* <SwiperSlide>
                                <div className="slide">
                                    <div className="info">
                                        <div className="image">
                                            <img src={EFULife} alt="testimonial" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slide">
                                    <div className="info">
                                        <div className="image">
                                            <img src={Sindh} alt="testimonial" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> */}
							<SwiperSlide>
								<div className="slide">
									<div className="info">
										<div className="image">
											<img src={FROME} alt="testimonial" />
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="info">
										<div className="image">
											<img src={BRYT} alt="testimonial" />
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="info">
										<div className="image">
											<img src={SDG} alt="testimonial" />
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="info">
										<div className="image invert">
											<img src={NEFT} alt="testimonial" />
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className="slide">
									<div className="info">
										<div className="image">
											<img src={SaveDaily} alt="testimonial" />
										</div>
									</div>
								</div>
							</SwiperSlide>
						</Swiper>
					</Col>
				</Row>
			</div>
		</section>
	);
}

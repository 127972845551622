import React from "react";
import { Row, Col } from "react-bootstrap";

import Python from "../img/technologies/data-science/python.png";
import ScikitLearn from "../img/technologies/data-science/scikit-learn.png";
import Keras from "../img/technologies/data-science/keras.png";
import TensorFlow from "../img/technologies/data-science/tenserflow.png";
import PyTorch from "../img/technologies/data-science/pytorch.png";
import ApacheSpark from "../img/technologies/data-science/apache-spark.png";
import R from "../img/technologies/data-science/r.png";
import KNIME from "../img/technologies/data-science/knime.png";
import Hadoop from "../img/technologies/data-science/hadoop.png";
import ApacheAirflow from "../img/technologies/data-science/airflow.png";
import ApacheAirbase from "../img/technologies/data-science/hbase.png";

import AngularJS from "../img/technologies/frontend/angular.png";
import ReactJS from "../img/technologies/frontend/react.png";
import VueJS from "../img/technologies/frontend/vue.png";
import NextJS from "../img/technologies/frontend/next.png";
import JQuery from "../img/technologies/frontend/jquery.png";
import Javascript from "../img/technologies/frontend/javascript.png";
import ViteJS from "../img/technologies/frontend/vite.png";
import CSS from "../img/technologies/frontend/css.png";
import Bootstrap from "../img/technologies/frontend/bootstrap.png";
import Tailwind from "../img/technologies/frontend/tailwind.png";
import HTML from "../img/technologies/frontend/html.png";

import ASPDOTNET from "../img/technologies/backend/aspdotnet.png";
import NodeJS from "../img/technologies/backend/nodejs.png";
import DOTNETCORE from "../img/technologies/backend/dotnetcore.png";
import PHP from "../img/technologies/backend/php.png";
import C from "../img/technologies/backend/c.png";
import CPlusPlus from "../img/technologies/backend/c_plus_plus.png";
import CSharp from "../img/technologies/backend/c_sharp.png";

import iOS from "../img/technologies/mobile/ios.png";
import Android from "../img/technologies/mobile/android.png";
import Swift from "../img/technologies/mobile/swift.png";
import Flutter from "../img/technologies/mobile/flutter.png";
import Ionic from "../img/technologies/mobile/ionic.png";
import ReactNative from "../img/technologies/mobile/reactnative.png";
import Xamarin from "../img/technologies/mobile/xamarin.png";

import SQLServer from "../img/technologies/database/sql-server.png";
import MySQL from "../img/technologies/database/mysql.png";
import PostgreSQL from "../img/technologies/database/postgre.png";
import MongoDB from "../img/technologies/database/mongodb.png";
import DynamoDB from "../img/technologies/database/dynamodb.png";
import SQLite from "../img/technologies/database/sqlite.png";
import Firebase from "../img/technologies/database/firebase.png";
import MariaDB from "../img/technologies/database/mariadb.png";
import Redis from "../img/technologies/database/redis.png";
import AmazonRDS from "../img/technologies/database/aws-rds.png";
import CassandraDB from "../img/technologies/database/cassandra.png";
import CouchDB from "../img/technologies/database/couchdb.png";

import PowerBI from "../img/technologies/data-visualization/power-bi.png";
import ChartJS from "../img/technologies/data-visualization/chartjs.png";
import Plotly from "../img/technologies/data-visualization/plotly.png";
import D3JS from "../img/technologies/data-visualization/d3.png";
import Highcharts from "../img/technologies/data-visualization/highcharts.png";
import Anychart from "../img/technologies/data-visualization/anychart.png";
import GoogleCharts from "../img/technologies/data-visualization/google-charts.png";
import Amcharts from "../img/technologies/data-visualization/amcharts.png";

export default function Technologies() {
	return (
		<>
			<div className="main-content">
				<div className="content-header header-bg">
					<div className="codex-container">
						<Row>
							<Col lg="12">
								<div className="header-text">
									<h2>Technologies</h2>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="content pt-0">
					<div className="codex-container">
						<Row className="gx-5 technology-row align-items-center" id="data-science" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Data Science & AI
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={Python} alt="python" />
										<span>Python</span>
									</div>
									<div className="logo">
										<img src={ScikitLearn} alt="scikit-learn" />
										<span>Scikit Learn</span>
									</div>
									<div className="logo">
										<img src={Keras} alt="keras" />
										<span>Keras</span>
									</div>
									<div className="logo">
										<img src={TensorFlow} alt="tenserflow" />
										<span>Tensor Flow</span>
									</div>
									<div className="logo">
										<img src={PyTorch} alt="pytorch" />
										<span>PyTorch</span>
									</div>
									<div className="logo">
										<img src={ApacheSpark} alt="apache-spark" />
										<span>Apache Spark</span>
									</div>
									<div className="logo">
										<img src={R} alt="r" />
										<span>R</span>
									</div>
									<div className="logo">
										<img src={KNIME} alt="knime" />
										<span>KNIME</span>
									</div>
									<div className="logo">
										<img src={Hadoop} alt="hadoop" />
										<span>Hadoop</span>
									</div>
									<div className="logo">
										<img src={ApacheAirflow} alt="airflow" />
										<span>Apache Airflow</span>
									</div>
									<div className="logo">
										<img src={ApacheAirbase} alt="hbase" />
										<span>Apache Airbase</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="gx-5 technology-row align-items-center" id="frontend" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Frontend
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={AngularJS} alt="angular" />
										<span>AngularJS</span>
									</div>
									<div className="logo">
										<img src={ReactJS} alt="react" />
										<span>ReactJS</span>
									</div>
									<div className="logo">
										<img src={VueJS} alt="vue" />
										<span>VueJS</span>
									</div>
									<div className="logo">
										<img src={NextJS} alt="next" />
										<span>NextJS</span>
									</div>
									<div className="logo">
										<img src={JQuery} alt="jquery" />
										<span>JQuery</span>
									</div>
									<div className="logo">
										<img src={Javascript} alt="javascript" />
										<span>Javascript</span>
									</div>
									<div className="logo">
										<img src={ViteJS} alt="vite" />
										<span>ViteJS</span>
									</div>
									<div className="logo">
										<img src={CSS} alt="css" />
										<span>CSS</span>
									</div>
									<div className="logo">
										<img src={Bootstrap} alt="bootstrap" />
										<span>Bootstrap</span>
									</div>
									<div className="logo">
										<img src={Tailwind} alt="tailwind" />
										<span>Tailwind</span>
									</div>
									<div className="logo">
										<img src={HTML} alt="html" />
										<span>HTML</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="gx-5 technology-row align-items-center" id="backend" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Backend
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={Python} alt="python" />
										<span>Python</span>
									</div>
									<div className="logo">
										<img src={ASPDOTNET} alt="aspdotnet" />
										<span>ASP .NET</span>
									</div>
									<div className="logo">
										<img src={NodeJS} alt="node" />
										<span>NodeJS</span>
									</div>
									<div className="logo">
										<img src={DOTNETCORE} alt="dotnetcore" />
										<span>.NET Core</span>
									</div>
									<div className="logo">
										<img src={PHP} alt="php" />
										<span>PHP</span>
									</div>
									<div className="logo">
										<img src={C} alt="c" />
										<span>C</span>
									</div>
									<div className="logo">
										<img src={CPlusPlus} alt="c_plus_plus" />
										<span>C++</span>
									</div>
									<div className="logo">
										<img src={CSharp} alt="c_sharp" />
										<span>C#</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="gx-5 technology-row align-items-center" id="mobile" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Mobile
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={iOS} alt="ios" />
										<span>iOS</span>
									</div>
									<div className="logo">
										<img src={Android} alt="android" />
										<span>Android</span>
									</div>
									<div className="logo">
										<img src={Swift} alt="swift" />
										<span>Swift</span>
									</div>
									<div className="logo">
										<img src={Flutter} alt="flutter" />
										<span>Flutter</span>
									</div>
									<div className="logo">
										<img src={Ionic} alt="ionic" />
										<span>Ionic</span>
									</div>
									<div className="logo">
										<img src={ReactNative} alt="reactnative" />
										<span>React Native</span>
									</div>
									<div className="logo">
										<img src={Xamarin} alt="xamarin" />
										<span>Xamarin</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="gx-5 technology-row align-items-center" id="database" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Database
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={SQLServer} alt="sql-server" />
										<span>SQL Server</span>
									</div>
									<div className="logo">
										<img src={MySQL} alt="mysql" />
										<span>MySQL</span>
									</div>
									<div className="logo">
										<img src={PostgreSQL} alt="postgre" />
										<span>PostgreSQL</span>
									</div>
									<div className="logo">
										<img src={MongoDB} alt="mongodb" />
										<span>MongoDB</span>
									</div>
									<div className="logo">
										<img src={DynamoDB} alt="dynamodb" />
										<span>DynamoDB</span>
									</div>
									<div className="logo">
										<img src={SQLite} alt="sqlite" />
										<span>SQLite</span>
									</div>
									<div className="logo">
										<img src={Firebase} alt="firebase" />
										<span>Firebase</span>
									</div>
									<div className="logo">
										<img src={MariaDB} alt="mariadb" />
										<span>MariaDB</span>
									</div>
									<div className="logo">
										<img src={Redis} alt="redis" />
										<span>Redis</span>
									</div>
									<div className="logo">
										<img src={AmazonRDS} alt="aws-rds" />
										<span>Amazon RDS</span>
									</div>
									<div className="logo">
										<img src={CassandraDB} alt="cassandra" />
										<span>CassandraDB</span>
									</div>
									<div className="logo">
										<img src={CouchDB} alt="couchdb" />
										<span>CouchDB</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="gx-5 technology-row align-items-center" id="dataVisualization" data-aos="fade-up">
							<Col lg={12}>
								<h2 className="content-h mb-5">
									Data Visualization
								</h2>
								<div className="tech-logos">
									<div className="logo">
										<img src={PowerBI} alt="power-bi" />
										<span>Power BI</span>
									</div>
									<div className="logo">
										<img src={ChartJS} alt="chartjs" />
										<span>ChartJS</span>
									</div>
									<div className="logo">
										<img src={Plotly} alt="plotly" />
										<span>Plotly</span>
									</div>
									<div className="logo">
										<img src={D3JS} alt="d3" />
										<span>D3JS</span>
									</div>
									<div className="logo">
										<img src={Highcharts} alt="highcharts" />
										<span>Highcharts</span>
									</div>
									<div className="logo">
										<img src={Anychart} alt="anychart" />
										<span>Anychart</span>
									</div>
									<div className="logo">
										<img src={GoogleCharts} alt="google-charts" />
										<span>Google Charts</span>
									</div>
									<div className="logo">
										<img src={Amcharts} alt="amcharts" />
										<span>Amcharts</span>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}

import React from "react";
import { Row, Col } from "react-bootstrap";

import BRYT from "../img/testimonials/bryt.png";
import FROME from "../img/testimonials/frome-w.png";
// import Sindh from "../img/testimonials/sindh.png";
// import EFULife from "../img/testimonials/efu-life.png";
import SDG from "../img/testimonials/sdg-w.png";
import SEI from "../img/testimonials/sei.png";
import NEFT from "../img/testimonials/neft.png";
import SaveDaily from "../img/testimonials/save-daily.png";
import Vybe from "../img/testimonials/vybe.png";

export default function Portfolio() {
	return (
		<>
			<div className="main-content">
				<div className="content-header header-bg">
					<div className="codex-container">
						<Row>
							<Col lg="12">
								<div className="header-text">
									<h2>Portfolio</h2>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="content">
					<div className="codex-container">
						<Row className="gx-5 align-items-center">
							<Col lg={12}>
								<h2 className="content-h mb-5 mt-5 mt-lg-0" data-aos="fade-up">
									Our Work
								</h2>
								<Row className="g-5" data-aos="fade-up">
									<Col xxl={4} md={6}>
										<a href="https://bryt.codexnow.com/" target="_blank" rel="noreferrer">
											<div className="projects bryt">
												<img src={BRYT} alt="bryt" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://www.firstroundsonme.co/" target="_blank" rel="noreferrer">
											<div className="projects frome">
												<img src={FROME} alt="frome" />
											</div>
										</a>
									</Col>
									{/* <Col xxl={4} md={6}>
										<a href="/" target="_blank" rel="noreferrer">
											<div className="projects pmis">
												<img src={Sindh} alt="sindh" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://www.efulife.com/" target="_blank" rel="noreferrer">
											<div className="projects efu">
												<img src={EFULife} alt="efu-life" />
											</div>
										</a>
									</Col> */}
									<Col xxl={4} md={6}>
										<a href="https://sdgknowledgevault.com/" target="_blank" rel="noreferrer">
											<div className="projects sdg">
												<img src={SDG} alt="sdg" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://www.simpleenergyinc.com/" target="_blank" rel="noreferrer">
											<div className="projects sei">
												<img src={SEI} alt="sei" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://www.neftvodkaus.com/" target="_blank" rel="noreferrer">
											<div className="projects neft">
												<img src={NEFT} alt="neft" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://www.savedaily.com/marketing/" target="_blank" rel="noreferrer">
											<div className="projects savedaily">
												<img src={SaveDaily} alt="save-daily" />
											</div>
										</a>
									</Col>
									<Col xxl={4} md={6}>
										<a href="https://vybetogether.com/" target="_blank" rel="noreferrer">
											<div className="projects vybe">
												<img src={Vybe} alt="vybe" />
											</div>
										</a>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}

import React from "react";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Process from "../Process";
import Contact from "../Contact";

import ProductDev from "../../img/services/engineering/product.jpg";
import RequirementEng from "../../img/services/engineering/requirement.jpg";
import SoftwareEng from "../../img/services/engineering/software.jpg";
import DataEng from "../../img/services/engineering/data.jpg";
import UsabilityEng from "../../img/services/engineering/usability.jpg";
import QualityEng from "../../img/services/engineering/quality-testing.jpg";
import DevOps from "../../img/services/engineering/devops.jpg";

// import Banner from "../../img/banner.png";

export default function Engineering() {
	return (
		<>
			<div className="main-content">
				<div className="content-header">
					{/* <img src={Banner} alt="banner" /> */}
					<div className="codex-container">
						<div className="header-text-content">
							<h2>Engineering Services</h2>
							<p>
								CodexNow provides on-demand engineering services in numerous domains to fulfill its customer needs.
								With dedicated teams, we ensure high quality work focusing on customer requirements. We specialize
								in providing engineering services in software development , product development , big data
								engineering , requirements engineering , usability and quality engineering and DevOps.
							</p>
						</div>
					</div>
				</div>
				<div className="cta-banner engineering">
					<h2>Let us know which Engineering Service you are looking for?</h2>
					<HashLink to="/services/engineering/#contact" className="btn">
						Enquire Now
					</HashLink>
				</div>
				<div className="content">
					<div className="codex-container">
						<Row className="gx-5">
							<Col lg={12} className="mb-4">
								<h2 className="content-h my-5">
									Our Engineering Services
								</h2>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="product-development">
								<div className="content-box">
									<div className="image-area">
										<img src={ProductDev} alt="engineering" />
									</div>
									<div className="text">
										<h3>Product Development</h3>
										<p>
											Codexnow provides custom product development services tailored to the needs of a wide range of
											industries including healthcare, fintech , insurance , banking and education. Codex’s team of
											business analysts ,product engineers, subject matter and UX experts help to validate the feasibility
											of your idea , build a prototype , define the technology stack and outline and follow the complete
											development path in accordance with your plans . We ensure that you get a high quality product
											meeting your business and financial goals.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="requirements-engineering">
								<div className="content-box">
									<div className="image-area">
										<img src={RequirementEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Requirements Engineering</h3>
										<p>
											As a consultancy service , we provide comprehensive support in evaluating , validating and
											documenting functional , non-functional and data requirements related to the design and development
											of a software product or analytical / machine learning use case . Our requirements engineering team
											makes the task of product and use case development easy and streamlined enabling you take a
											confident and risk-free lead towards your planned initiatives.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="software-engineering">
								<div className="content-box">
									<div className="image-area">
										<img src={SoftwareEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Software Engineering</h3>
										<p>
											Software engineering services provided by codexnow span the complete software development life cycle
											covering custom solutions from scratch ensuring seamless integration with current systems and legacy
											system redesign . We provide software engineering services in healthcare , retail, banking ,
											Insurance , public sector and education in building information systems , financial software ,
											analytical systems , machine learning and AI based solutions and all other categories of industry
											specific software.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="data-engineering">
								<div className="content-box">
									<div className="image-area">
										<img src={DataEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Data Engineering</h3>
										<p>
											Data engineering is the process of bringing raw data into a format which is suitable for analytics.
											It includes ingesting raw data from various SQL and NOSQL data stores and data lakes , cleaning and
											transforming it into different forms like OLAP cubes for data warehousing or BI related tasks or one
											single flat file for a machine learning use case. Our dedicated team of data engineers helps in
											designing and deploying complete ETL pipelines for downstream analytical tasks to be performed on
											local dedicated servers or cloud computing platforms like AWS, Azure or Google cloud.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={UsabilityEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Usability Engineering</h3>
										<p>
											Through its dedicated team of usability engineers , codexnow offers the services of usability
											engineering and testing at different stages of software development life cycle . You can seek
											consultancy in parallel to your software development task or run independent usability tests for
											your finished products to improve user experience and achieve efficiency and elegance in interface
											design. We cover all aspects of user interfaces including website; web ,mobile and desktop
											applications , information systems , analytical and machine learning applications and many other
											industry specific software applications.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={QualityEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Quality Engineering & Testing</h3>
										<p>
											At codexnow, we offer a complete suite of quality assurance services including functional ,
											performance , usability and compatibility testing for web , mobile, desktop and cloud applications.
											Our quality assurance engineers analyze the target software and after consultation with the
											stakeholders prepare a comprehensive testing plan which is carried out using manual or automated
											testing tools. Various methods including unit ,integration, smoke and cross platform testing are
											used to identify potential loopholes and produce a high standard QA tested product or solution.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={DevOps} alt="engineering" />
									</div>
									<div className="text">
										<h3>DevOps</h3>
										<p>
											We consolidate development and operations to ensure transparent and quick software delivery cycles
											with our dedicated DevOps team. You can seek consultancy regarding DevOps assessment of current
											workflows, DevOPs automation to automate various aspects of software development and DevOPs
											monitoring to monitor the performance of a newly deployed DevOps strategy. No matter if you are a
											large enterprise or a startup , our DevOps services ensure continuous deployment and delivery of
											products in the market keeping up the competitive pace while cutting down the cost and complexities.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={ProductDev} alt="engineering" />
									</div>
									<div className="text">
										<h3>Product Development</h3>
										<p>
											Codexnow provides custom product development services tailored to the needs of a wide range of
											industries including healthcare, fintech , insurance , banking and education. Codex’s team of
											business analysts ,product engineers, subject matter and UX experts help to validate the feasibility
											of your idea , build a prototype , define the technology stack and outline and follow the complete
											development path in accordance with your plans . We ensure that you get a high quality product
											meeting your business and financial goals.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={RequirementEng} alt="engineering" />
									</div>
									<div className="text">
										<h3>Requirements Engineering</h3>
										<p>
											As a consultancy service , we provide comprehensive support in evaluating , validating and
											documenting functional , non-functional and data requirements related to the design and development
											of a software product or analytical / machine learning use case . Our requirements engineering team
											makes the task of product and use case development easy and streamlined enabling you take a
											confident and risk-free lead towards your planned initiatives.
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<Process />
					<Contact />
				</div>
			</div>
		</>
	);
}

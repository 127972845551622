import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../../img/codexlogo-1.png";
import LogoWhite from "../../img/codexlogo-1-w.png";
import { Offcanvas } from "react-bootstrap";

export default function Header() {
	const [scroll, setScroll] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [mobileMenu, setMobileMenu] = useState(false);
	const [mobileLinkDropdown, setMobileLinkDropdown] = useState({
		services: false,
		sectors: false,
		technologies: false,
		portfolio: false,
	});

	const mobileMenuClose = () => setMobileMenu(false);
	const mobileMenuShow = () => setMobileMenu(true);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	const { pathname } = useLocation();
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 25);
		});
	}, []);
	return (
		<header
			className={`header fixed-top ${scroll || pathname !== "/" ? "scrolled" : ""} ${isHovering ? "hovered" : ""} ${pathname === "/" ? "home" : ""}`}
		>
			<div className="codex-container">
				<div className="inner">
					<div className="logo">
						<Link to="/">
							<img
								src={document.body.classList.contains("dark") && scroll ? LogoWhite : Logo}
								alt="logo"
								width="175"
								className="py-3"
								// height="42"
							/>
						</Link>
					</div>
					<ul className="nav d-none d-xl-flex">
						<li className="dropdown">
							<Link to="/services" className={`nav-link btn btn-link mega ${pathname.includes("/services") ? "active" : ""}`}>
								Services
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div className="mega-menu normal">
								<ul className="px-4 py-1">
									<li>
										<Link to="/services/#engineering" className={`${pathname.includes("/engineering") ? "active" : ""}`}>
											Engineering Services
										</Link>
									</li>
									<li>
										<Link to="/services/#data-science" className={`${pathname.includes("/data-science") ? "active" : ""}`}>
											Data Science / AI
										</Link>
									</li>
									<li>
										<Link to="/services/#design" className={`${pathname.includes("/design") ? "active" : ""}`}>
											Design
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link to="/sectors" className={`nav-link btn btn-link mega ${pathname === "/sectors" ? "active" : ""}`}>
								Sectors
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div className="mega-menu normal">
								<ul className="px-4 py-1">
									<li>
										<Link to="/sectors#healthcare">Healthcare</Link>
									</li>
									<li>
										<Link to="/sectors#finance">Finance</Link>
									</li>
									<li>
										<Link to="/sectors#retail">Retail</Link>
									</li>
									<li>
										<Link to="/sectors#insurance">Insurance</Link>
									</li>
									<li>
										<Link to="/sectors#government">Government</Link>
									</li>
									<li>
										<Link to="/sectors#education">Education</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link to="/technologies" className={`nav-link btn btn-link mega ${pathname === "/technologies" ? "active" : ""}`}>
								Technologies
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div className="mega-menu normal">
								<ul className="px-4 py-1">
									<li>
										<Link to="/technologies#data-science">Data Science & AI</Link>
									</li>
									<li>
										<Link to="/technologies#frontend">Frontend</Link>
									</li>
									<li>
										<Link to="/technologies#backend">Backend</Link>
									</li>
									<li>
										<Link to="/technologies#mobile">Mobile</Link>
									</li>
									<li>
										<Link to="/technologies#database">Database</Link>
									</li>
									<li>
										<Link to="/technologies#dataVisualization">Data Visualization</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link to="/portfolio" className={`nav-link btn btn-link mega ${pathname === "/portfolio" ? "active" : ""}`}>
								Portfolio
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div className="mega-menu normal">
								<ul className="px-4 py-1">
									<li>
										<a href="https://bryt.codexnow.com/" target="_blank" rel="noreferrer">
											Bryt
										</a>
									</li>
									<li>
										<a href="https://www.firstroundsonme.co/" target="_blank" rel="noreferrer">
											Frome
										</a>
									</li>
									{/* <li>
										<a href="/">PMIS</a>
									</li>
									<li>
										<a href="https://www.efulife.com/" target="_blank" rel="noreferrer">
											EFU Life
										</a>
									</li> */}
									<li>
										<a href="https://sdgknowledgevault.com/" target="_blank" rel="noreferrer">
											SDG
										</a>
									</li>
									<li>
										<a href="https://www.simpleenergyinc.com/" target="_blank" rel="noreferrer">
											Simple Energy
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
							<Link to="/about-us" className={`nav-link ${pathname === "/about-us" ? "active" : ""}`}>
								About Us
							</Link>
						</li>
						<li className="d-flex align-items-center ps-5">
							<HashLink to="/#contact">
								<span className="btn header-btn btn-link">Contact Us</span>
							</HashLink>
						</li>
					</ul>
					<div className="mobile-menu d-block d-xl-none" onClick={mobileMenuShow}>
						<i className="fa-solid fa-bars"></i>
					</div>
				</div>
			</div>
			<Offcanvas show={mobileMenu} onHide={mobileMenuClose} placement="end">
				<Offcanvas.Header className="justify-content-end" closeButton></Offcanvas.Header>
				<Offcanvas.Body>
					<ul className="nav flex-column">
						<li className="dropdown">
							<Link
								to="/services"
								className={`nav-link btn btn-link mega ${pathname.includes("/services") ? "active" : ""}`}
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: !mobileLinkDropdown.services,
										sectors: false,
										technologies: false,
										portfolio: false,
									});
								}}
							>
								Services
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div>
								<ul className={`px-4 py-1 ${mobileLinkDropdown.services ? "d-block" : "d-none"}`}>
									<li>
										<Link
											to="/services/#engineering"
											className={`${pathname.includes("/engineering") ? "active" : "nav-link ps-1 pt-0"}`}
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: true,
													sectors: false,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Engineering Services
										</Link>
									</li>
									<li>
										<Link
											to="/services/#data-science"
											className={`${pathname.includes("/data-science") ? "active" : "nav-link ps-1 pt-0"}`}
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: true,
													sectors: false,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Data Science / AI
										</Link>
									</li>
									<li>
										<Link
											to="/services/#design"
											className={`${pathname.includes("/design") ? "active" : "nav-link ps-1 pt-0"}`}
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: true,
													sectors: false,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Design
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link
								to="/sectors"
								className={`nav-link btn btn-link mega ${pathname === "/sectors" ? "active" : ""}`}
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: false,
										sectors: !mobileLinkDropdown.sectors,
										technologies: false,
										portfolio: false,
									});
								}}
							>
								Sectors
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div>
								<ul className={`px-4 py-1 ${mobileLinkDropdown.sectors ? "d-block" : "d-none"}`}>
									<li>
										<Link
											to="/sectors#healthcare"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Healthcare
										</Link>
									</li>
									<li>
										<Link
											to="/sectors#finance"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Finance
										</Link>
									</li>
									<li>
										<Link
											to="/sectors#retail"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Retail
										</Link>
									</li>
									<li>
										<Link
											to="/sectors#insurance"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Insurance
										</Link>
									</li>
									<li>
										<Link
											to="/sectors#government"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Government
										</Link>
									</li>
									<li>
										<Link
											to="/sectors#education"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: true,
													technologies: false,
													portfolio: false,
												});
											}}
										>
											Education
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link
								to="/technologies"
								className={`nav-link btn btn-link mega ${pathname === "/technologies" ? "active" : ""}`}
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: false,
										sectors: false,
										technologies: !mobileLinkDropdown.technologies,
										portfolio: false,
									});
								}}
							>
								Technologies
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div>
								<ul className={`px-4 py-1 ${mobileLinkDropdown.technologies ? "d-block" : "d-none"}`}>
									<li>
										<Link
											to="/technologies#data-science"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Data Science & AI
										</Link>
									</li>
									<li>
										<Link
											to="/technologies#frontend"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Frontend
										</Link>
									</li>
									<li>
										<Link
											to="/technologies#backend"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Backend
										</Link>
									</li>
									<li>
										<Link
											to="/technologies#mobile"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Mobile
										</Link>
									</li>
									<li>
										<Link
											to="/technologies#database"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Database
										</Link>
									</li>
									<li>
										<Link
											to="/technologies#dataVisualization"
											className="nav-link ps-1 pt-0"
											onClick={() => {
												setMobileMenu(false);
												setMobileLinkDropdown({
													...mobileLinkDropdown,
													services: false,
													sectors: false,
													technologies: true,
													portfolio: false,
												});
											}}
										>
											Data Visualization
										</Link>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link
								to="/portfolio"
								className={`nav-link btn btn-link mega ${pathname === "/portfolio" ? "active" : ""}`}
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: false,
										sectors: false,
										technologies: false,
										portfolio: !mobileLinkDropdown.portfolio,
									});
								}}
							>
								Portfolio
								<i className="fa-regular fa-angle-down"></i>
							</Link>
							<div>
								<ul className={`px-4 py-1 ${mobileLinkDropdown.portfolio ? "d-block" : "d-none"}`}>
									<li>
										<a href="https://bryt.codexnow.com/" target="_blank" rel="noreferrer" className="nav-link ps-1 pt-0">
											Bryt
										</a>
									</li>
									<li>
										<a href="https://www.firstroundsonme.co/" target="_blank" rel="noreferrer" className="nav-link ps-1 pt-0">
											Frome
										</a>
									</li>
									{/* <li>
										<a href="/" className="nav-link ps-1 pt-0">
											PMIS
										</a>
									</li>
									<li>
										<a href="https://www.efulife.com/" target="_blank" rel="noreferrer" className="nav-link ps-1 pt-0">
											EFU Life
										</a>
									</li> */}
									<li>
										<a href="https://sdgknowledgevault.com/" target="_blank" rel="noreferrer" className="nav-link ps-1 pt-0">
											SDG
										</a>
									</li>
									<li>
										<a href="https://www.simpleenergyinc.com/" target="_blank" rel="noreferrer" className="nav-link ps-1 pt-0">
											Simple Energy
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li className="dropdown">
							<Link
								to="/about-us"
								className={`nav-link ${pathname === "/about-us" ? "active" : ""}`}
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: false,
										sectors: false,
										technologies: false,
										portfolio: false,
									});
								}}
							>
								About Us
							</Link>
						</li>
						<li className="d-flex align-items-center mt-3">
							<HashLink
								to="/#contact"
								className="w-100"
								onClick={() => {
									setMobileMenu(false);
									setMobileLinkDropdown({
										...mobileLinkDropdown,
										services: false,
										sectors: false,
										technologies: false,
										portfolio: false,
									});
								}}
							>
								<span className="btn header-btn btn-link">Contact Us</span>
							</HashLink>
						</li>
					</ul>
				</Offcanvas.Body>
			</Offcanvas>
		</header>
	);
}

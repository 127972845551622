import React from "react";
import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Process from "../Process";
import Contact from "../Contact";

import Website from "../../img/services/design/website.jpg";
import Frontend from "../../img/services/design/frontend.jpg";
import UXUI from "../../img/services/design/ux-ui.jpg";
import Logo from "../../img/services/design/logo.jpg";

export default function Design() {
	return (
		<>
			<div className="main-content">
				<div className="content-header">
					{/* <img src={Banner} alt="banner" /> */}
					<div className="codex-container">
						<div className="header-text-content">
							<h2>Design</h2>
							<p>
								CodexNow helps organizations to distinctly stand in the digital world . We provide exceptional
								design services in creating innovative and user friendly UX/UI , logos , websites and user
								interfaces for conventional software applications as well as modern AI based systems. Our dedicated
								teams of UX/UI designers and usability experts work closely with the customer to get out of the box
								design solutions that help them stay digitally distinct with improved user experience.
							</p>
						</div>
					</div>
				</div>
				<div className="cta-banner engineering">
					<h2>We help Enterprises stand unique and elegant in the Digital World</h2>
					<HashLink to="/services/design/#contact" className="btn">
						Discuss your idea
					</HashLink>
				</div>
				<div className="content">
					<div className="codex-container">
						<Row className="gx-5">
							<Col lg={12} className="mb-4">
								<h2 className="content-h my-5">
									Our Design Services
								</h2>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="website-design">
								<div className="content-box">
									<div className="image-area">
										<img src={Website} alt="design" />
									</div>
									<div className="text">
										<h3>Website Design</h3>
										<p>
											If you are a startup looking for the launch of your website or an established business geared
											towards increasing your web presence , codexnow is here to help you . We offer full-stack web
											development services for companies in e-commerce , retail , manufacturing , real estate , banking ,
											education and other fields. From single-page applications to complex enterprise networks, we create
											custom websites quickly and reliably — exactly the way you envision them.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="frontend-development">
								<div className="content-box">
									<div className="image-area">
										<img src={Frontend} alt="design" />
									</div>
									<div className="text">
										<h3>Frontend Development</h3>
										<p>
											Codexnow offers frontend development services for a wide range of applications ranging from simple
											desktop applications , web applications and intelligent & analytical solutions to industry specific
											applications and smart devices. Our front-end development teams work in tandem with UI/UX teams
											ensuring an intuitive and seamless frontend by using both mainstream and specialized technologies .
											Let us know about your specific needs and we will do the rest.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="ux-ui-design">
								<div className="content-box">
									<div className="image-area">
										<img src={UXUI} alt="design" />
									</div>
									<div className="text">
										<h3>UX / UI Design</h3>
										<p>
											Our UX/UI engineers create visually appealing and compelling interfaces that are streamlined and
											engaging to attract and retain users.While our UX designers focusing on the target market and
											specific needs and goals of the product , prepare user journeys and wireframes , our UI experts
											convert them into interfaces which are consistent and engaging. This UI/UX working hand-in-hand
											produces aesthetically pleasing UX/UI designs for software products , websites and applications.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5" id="logo-design">
								<div className="content-box">
									<div className="image-area">
										<img src={Logo} alt="design" />
									</div>
									<div className="text">
										<h3>Logo Design</h3>
										<p>
											Codex Now offers logo design services through its daughter company LogoSkill. Based in the USA , we
											are the best logo design company along with the flayer for digital marketing, expertise in social
											media, PPC, design consultancy for SMEs, Start-ups, and for individuals like youtubers, bloggers and
											influencers. With our award winning customer support, we assure that you are in the hands of expert
											designers and developers who carry the soul of an artist who delivers only the best.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={Website} alt="design" />
									</div>
									<div className="text">
										<h3>Website Design</h3>
										<p>
											If you are a startup looking for the launch of your website or an established business geared
											towards increasing your web presence , codexnow is here to help you . We offer full-stack web
											development services for companies in e-commerce , retail , manufacturing , real estate , banking ,
											education and other fields. From single-page applications to complex enterprise networks, we create
											custom websites quickly and reliably — exactly the way you envision them.
										</p>
									</div>
								</div>
							</Col>
							<Col xl={4} md={6} className="mb-5">
								<div className="content-box">
									<div className="image-area">
										<img src={Frontend} alt="design" />
									</div>
									<div className="text">
										<h3>Frontend Development</h3>
										<p>
											Codexnow offers frontend development services for a wide range of applications ranging from simple
											desktop applications , web applications and intelligent & analytical solutions to industry specific
											applications and smart devices. Our front-end development teams work in tandem with UI/UX teams
											ensuring an intuitive and seamless frontend by using both mainstream and specialized technologies .
											Let us know about your specific needs and we will do the rest.
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<Process />
					<Contact />
				</div>
			</div>
		</>
	);
}
